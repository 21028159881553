import React from "react";
import { Assets } from "./Assets";
import { Balance } from "./Balance";
import { Coin } from "./Coin";
import { Mempool } from "./Mempool";

interface IProps {
  address: string;
  firebase: any;
}
export function App({ address, firebase }: IProps) {
  const [isAlive, setIsAlive] = React.useState(true);
  React.useEffect(() => {
    const key = "/ticker";
    firebase
      .database()
      .ref(key)
      .on("value", function (snapshot) {
        const time = snapshot.val();
        const now = new Date().getTime();
        const minutes = 8 * 60000;
        console.log("TIME", now - time);
        setIsAlive(now - time < minutes);
      });
  }, []);

  if (isAlive === false) {
    return <div>This demo is offline right now. Please try again later</div>;
  }
  return (
    <div className="glass-card">
      <Coin image1={null} image2={null} />
      <ul>
        <li>GIVEN that some kids have a Lemonade Stand</li>
        <li>WHEN a customer pays with RVN or Lemonade Tokens</li>
        <li>THEN the kids should see that instantly</li>
      </ul>
      {/* 
      This demo intends to prove that Raven is fast enough for real world
      adoption all over the globe. */}
       Check out Ravencoins INSANE performance by
      sending some RVN or assets/tokens to the address below
      <br /> 
      <div style={{ fontStyle: "italic", paddingTop: "14px", fontSize: "80%" }}>
        All funds will be donated to the Ravencoin Foundation
      </div>
      <div className="logo"></div>
      <Balance firebase={firebase} address={address} />
      <Mempool firebase={firebase} address={address} />
      <div className="glass-card">
        <div style={{ paddingBottom: "8px" }}>
          <strong>PAY to this address</strong>
        </div>
        <img
          style={{ marginTop: "5px" }}
          src="https://api.qrserver.com/v1/create-qr-code/?data=RRxfNyYEzunwv4HbrquZmS6njYZvxXTKC6&size=100x100&margin=0"
        ></img>
        <input
          type="text"
          id="address"
          value={address}
          style={{ display: "none", padding: "10px" }}
        ></input>
        <div style={{ paddingBottom: "12px" }}>
          <small>{address}</small>
        </div>
        <button
          onClick={myCopy}
          style={{
            display: "block",
            borderRadius: "5px",
            background: "white",
            border: "0",
            padding: "10px",
          }}
        >
          Copy
        </button>
      </div>
      <Assets firebase={firebase} address={address} />
    </div>
  );
}

function myCopy() {
  /* Get the text field */
  var copyText = document.getElementById("address") as HTMLInputElement;
  //@ts-ignore
  window.Clipboard.copy(copyText.value);
  alert("Copied: " + copyText.value);
}
