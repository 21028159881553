import React from "react";

interface IProps {
  address: string;
  firebase: any;
}
export function Balance({ address, firebase }) {
  const [balance, setBalance] = React.useState(null);

  React.useEffect(() => {
    const key = "/balance/" + address;
    firebase
      .database()
      .ref(key)
      .on("value", function (snapshot) {
        setBalance(snapshot.val());
      });
  }, []);

  const json = JSON.stringify(balance);

  return (
    <p>
      <strong>Balance</strong>: {json} RVN
    </p>
  );
}
