// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app";
// If you are using v7 or any earlier version of the JS SDK, you should import firebase using namespace import
// import * as firebase from "firebase/app"

// Add the Firebase products that you want to use
import "firebase/database";
import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
var firebaseConfig = {
  apiKey: "AIzaSyA7GIQ5QWsWxQHDOVvykSWHvf9s_ngsV2Q",
  authDomain: "insane-9c267.firebaseapp.com",
  databaseURL:
    "https://insane-9c267-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "insane-9c267",
  storageBucket: "insane-9c267.appspot.com",
  messagingSenderId: "884728165247",
  appId: "1:884728165247:web:c46cf60471f4e458a3604a",
};

firebase.initializeApp(firebaseConfig);
const address = "RRxfNyYEzunwv4HbrquZmS6njYZvxXTKC6";

ReactDOM.render(
  <App firebase={firebase} address={address} />,
  document.getElementById("app")
);
//@ts-ignore
window["Clipboard"] = (function (window, document, navigator) {
  var textArea, copy;

  function isOS() {
    return navigator.userAgent.match(/ipad|iphone/i);
  }

  function createTextArea(text) {
    textArea = document.createElement("textArea");
    textArea.value = text;
    document.body.appendChild(textArea);
  }

  function selectText() {
    var range, selection;

    if (isOS()) {
      range = document.createRange();
      range.selectNodeContents(textArea);
      selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      textArea.setSelectionRange(0, 999999);
    } else {
      textArea.select();
    }
  }

  function copyToClipboard() {
    document.execCommand("copy");
    document.body.removeChild(textArea);
  }

  copy = function (text) {
    createTextArea(text);
    selectText();
    copyToClipboard();
  };

  return {
    copy: copy,
  };
})(window, document, navigator);

