import React from "react";

interface IProps {
  address: string;
  firebase: any;
}
export function Assets({ address, firebase }:IProps) {
  const [assets, setAssets] = React.useState({});

  React.useEffect(() => {
    const key = "/assets/" + address;
    firebase
      .database()
      .ref(key)
      .on("value", function (snapshot) {
        const obj = snapshot.val();
        console.log("assets", obj);
        setAssets(obj);
      });
  }, []);

  return (
    <div>
      <h2>Assets</h2>
      <ListAssets assets={assets} />
    </div>
  );
}

function ListAssets({ assets }) {
  if (!assets) {
    return null;
  }
 
  const keys = Object.keys(assets);
  console.log("keys is" , keys);
  return (
    <table className="table" >
      <thead>
        <tr>
          <th>Token/asset</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody>
        {keys && keys.map(function (key) {
          //Decode base64
          const newKey = atob(key);
          return (
            <tr key={newKey}>
              <td> {newKey}</td>
              <td>{assets[key]}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
