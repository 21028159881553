import React from "react";

interface IProps {
  address: string;
  firebase: any;
}
export function Mempool({ address, firebase }) {
  const [mempool, setMempool] = React.useState({});

  React.useEffect(() => {
    const key = "/mempool";
    firebase
      .database()
      .ref(key)
      .on("value", function (snapshot) {
        const obj = snapshot.val();
        setMempool(obj);
      });
  }, []);

  if (!mempool) {
    return <div className="glass-card">No pending transactions</div>;
  }
  const asdf = mempool[address];
  if (!asdf) {
    return <div className="glass-card">Pending transactions</div>;
  }
  return (
    <div className="glass-card">
      <strong>Pending transactions</strong>
      {asdf.map(function (t) {
        return (
          <div>
            <div>
              <big>✓</big>Customer paid {t.details && t.details[0].amount}{" "}
              {t.assetName}
            </div>
            <div style={{ marginBottom: "10px" }}>
              <a
                target="_blank"
                href={"https://explorer.ravenland.org/tx/" + t.txid}
              >
                Check confirmations
              </a>
            </div>
          </div>
        );
      })}
    </div>
  );
}
